<template>
  <div class="form">
    <div class="wrapper" v-if="isContent">
      <Backlink title="contract" :service="currentServiceStr" :backlink="backlink" />
      <div class="content">
        <div class="content__container">
          <Contract :service="serviceData" />
        </div>
      </div>
    </div>

    <PopupText />
  </div>
</template>

<script>
import PopupText from '@/components/PopupText.vue'
import Backlink from '@/components/base/Backlink.vue'
import Contract from '@/components/Contract.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Contract_page',
  components: { Backlink, Contract, PopupText },
  mixins: [commonMixin],
  props: {
    backlink: { type: String, default: '/' }
  },
  data: () => ({ 
    isContent: false,
    currentServiceStr: null
  }),
  created () {
    this.showContent()
    this.loadServices()
  },
  computed: { popup_trigger () { return this.$store.state.popup} },
  watch: { 
    popup_trigger () { this.showContent() },
    currentService () {
      this.currentServiceStr = this.getServiceStr(this.currentService)
    }
  },
  methods: {
    showContent () {
      this.isContent = !this.isMobile || !this.$store.state.popup.id && (!this.isMobile || !this.$store.state.is_popup_shown)
    }
  }
}
</script>
